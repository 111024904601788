<template>
  <el-row class="warp">
    <el-form :inline="true" :model="searchForm" size="small">
      <el-form-item>
        <el-input v-model="searchForm.ckey" placeholder="键"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="primary" @click="handleReset">重置</el-button>
        <el-button type="primary" @click="showAddEditDialog()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="listData">
      <el-table-column prop="title" label="标题" width="200" size="small" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ckey" label="键" width="200" size="small"></el-table-column>
      <el-table-column prop="cvalue" label="值" width="200" :show-overflow-tooltip="true" size="small"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="small" @click="showAddEditDialog(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="delItemData(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--工具条-->
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="10"
      :page-sizes="[10, 20, 30, 50]"
      :total="total"
      style="float:right;"
    >
    </el-pagination>

    <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" append-to-body :close-on-click-modal="false">
      <el-form label-width="50px" :model="addEditForm" ref="addEditForm" size="small">
        <el-form-item label="标题">
          <el-input v-model="addEditForm.title" placeholder="请输入配置标题"></el-input>
        </el-form-item>
        <el-form-item label="键">
          <el-input v-model="addEditForm.ckey" placeholder="键"></el-input>
        </el-form-item>
        <el-form-item label="值">
          <el-input v-model="addEditForm.cvalue" type="textarea" placeholder="值"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click.native="addEditFormVisible = false">取消</el-button>
          <el-button type="primary" @click="addEditSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-row>
</template>

<script>
import API from '@/api/system/api_config'
import PagesMixins from '@/mixins/pagesMixins.js'
import _ from 'lodash'
const searchForm = {
  ckey: ''
}
const addEditForm = {
  title: '',
  ckey: '',
  cvalue: ''
}
export default {
  mixins: [PagesMixins],
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],

      addEditTitle: '',
      addEditType: '',
      addEditForm: _.cloneDeep(addEditForm),
      addEditFormVisible: false,
      pid: null
    }
  },
  props: {
    configId: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        pid: this.configId,
        ...this.searchForm
      }
      API.queryConfigItemList(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    showAddEditDialog(row) {
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      if (row) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
        this.addEditForm.configId = row.configId
        for (let key in this.addEditForm) {
          this.addEditForm[key] = row[key]
        }
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'
        this.addEditForm = _.cloneDeep(addEditForm)
      }
      this.addEditFormVisible = true
    },
    //新增编辑提交
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          params.pid = this.configId
          API.addConfigItem(params).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: this.addEditType === 'add' ? '新增成功' : '编辑成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
            }
          })
        }
      })
    },
    delItemData(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' })
        .then(() => {
          let args = {
            configId: row.configId,
            ckey: row.ckey
          }
          API.delConfigItem(args).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: '删除成功', duration: 1500 })
              this.getListData()
            }
          })
        })
        .catch(() => {})
    }
    /* addItems: function() {
      let pid = this.searchItemForm.pid
      let params = {
        pid: pid,
        ckey: this.addEditForm.ckey,
        cvalue: this.addEditForm.cvalue,
        title: this.addEditForm.title
      }
      API.addConfigItem(params).then(function(result) {
        if (result && result.data != 0) {
          this.$message.success({ showClose: true, message: '新增成功！', duration: 2000 })
          this.dialogAddItemContentVisible = false
          this.searchItem()
        } else {
          this.$message.error({ showClose: true, message: '请求出现异常', duration: 2000 })
        }
      })
    },
    editItems: function() {
      let pid = this.searchItemForm.pid
      let params = {
        pid: pid,
        ckey: this.editItemForm.ckey,
        cvalue: this.editItemForm.cvalue,
        title: this.editItemForm.title
      }
      console.info(params)
      API.addConfigItem(params).then(function(result) {
        if (result && result.data != 0) {
          this.$message.success({ showClose: true, message: '修改成功！', duration: 2000 })
          this.dialogEditItemContentVisible = false
          this.searchItem()
        } else {
          this.$message.error({ showClose: true, message: '请求出现异常', duration: 2000 })
        }
      })
    } */
  }
}
</script>
