<template>
  <el-row class="warp">
    <el-form :inline="true" :model="searchForm" size="small">
      <el-form-item>
        <el-input v-model="searchForm.title" placeholder="请输入配置标题" @keyup.enter.native="handleSearch"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.keyType" placeholder="请输入配置类型" @keyup.enter.native="handleSearch"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="primary" @click="handleReset">重置</el-button>
        <el-button type="primary" @click="updateCach">全量更新</el-button>
        <el-button type="primary" @click="showAddEditDialog()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table :data="listData">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="title" label="配置标题"></el-table-column>
      <el-table-column prop="ckey" label="键"></el-table-column>
      <el-table-column prop="keyType" label="键类型"></el-table-column>
      <el-table-column prop="projectNames" label="对应项目" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="scope">
          <el-button size="small" @click="showItems(scope.row.configId)">明细</el-button>
          <el-button size="small" @click="showAddEditDialog(scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="delData(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-size="10"
      :page-sizes="[10, 20, 30, 50]"
      :total="total"
      style="float:right;"
    >
    </el-pagination>

    <!--新增界面-->
    <el-dialog :title="addEditTitle" :visible.sync="addEditFormVisible" :close-on-click-modal="false">
      <el-form :model="addEditForm" label-width="80px" :rules="addEditFormRules" ref="addEditForm" size="small">
        <el-form-item label="配置标题" prop="title">
          <el-input v-model="addEditForm.title" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="keyType">
          <el-select v-model="addEditForm.keyType" placeholder="请选择">
            <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名" prop="projects">
          <el-select v-model="addEditForm.projects" multiple placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in projectOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="键" prop="ckey">
          <el-input v-model="addEditForm.ckey" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="值" prop="cvalue">
          <el-input v-model="addEditForm.cvalue" auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addEditFormVisible = false">取消</el-button>
        <el-button type="primary" @click.native="addEditSubmit">保存</el-button>
      </div>
    </el-dialog>
    <!--configItem-->
    <el-dialog v-if="configItemVisible" title="配置中心" :visible.sync="configItemVisible" :close-on-click-modal="false" width="800px">
      <config-item :configId="configId"></config-item>
    </el-dialog>
  </el-row>
</template>

<script>
import API from '@/api/system/api_config'
import PagesMixins from '@/mixins/pagesMixins.js'
import configItem from './configitem'
import _ from 'lodash'
const searchForm = {
  title: '',
  keyType: ''
}
const addEditForm = {
  title: '',
  ckey: '',
  cvalue: '',
  keyType: 'HASH',
  pid: 0,
  projects: []
}
export default {
  mixins: [PagesMixins],
  components: {
    configItem
  },
  data() {
    return {
      searchForm: _.cloneDeep(searchForm),
      listData: [],

      addEditTitle: '',
      addEditType: '',
      addEditForm: _.cloneDeep(addEditForm),
      addEditFormVisible: false,
      addEditFormRules: {
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        ckey: [{ required: true, message: '请输入', trigger: 'blur' }],
        projects: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      projectOptions: [],
      typeOptions: [
        {
          value: 'HASH',
          label: 'HASH'
        },
        {
          value: 'STR',
          label: 'STR'
        }
      ],
      configItemVisible: false,
      configId: null
    }
  },
  mounted() {
    this.handleSearch()
    this.loadProjectOptions()
  },
  methods: {
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      API.queryConfigList(params).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.listData = result.data.list
        }
      })
    },
    showAddEditDialog(row) {
      if (this.$refs.addEditForm !== undefined) {
        this.$refs.addEditForm.resetFields()
      }
      this.addEditForm = _.cloneDeep(addEditForm)
      if (row) {
        this.addEditTitle = '编辑'
        this.addEditType = 'edit'
        this.addEditForm.configId = row.configId
        for (let key in this.addEditForm) {
          this.addEditForm[key] = row[key]
        }
        this.addEditForm.projects = this.addEditForm.projects.map(e => e.toString())
      } else {
        this.addEditTitle = '新增'
        this.addEditType = 'add'
      }
      this.addEditFormVisible = true
    },
    //新增编辑提交
    addEditSubmit() {
      this.$refs.addEditForm.validate(valid => {
        if (valid) {
          let params = Object.assign({}, this.addEditForm)
          params.projects = params.projects.map(e => Number(e))
          params.cvalue = params.cvalue === null ? '' : params.cvalue
          params.pid = 0
          let response
          if (this.addEditType === 'add') {
            response = API.addConfig(params)
          } else {
            response = API.editConfig(params)
          }
          response.then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: this.addEditType === 'add' ? '新增成功' : '编辑成功', duration: 2000 })
              this.addEditFormVisible = false
              this.getListData()
            } else {
              this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
            }
          })
        }
      })
    },
    //删除
    delData(index, row) {
      this.$confirm('确认删除该记录吗?', '提示', { type: 'warning' })
        .then(() => {
          let args = {
            configId: row.configId,
            ckey: row.ckey
          }
          API.delConfig(args).then(result => {
            if (result && result.code === '000000') {
              this.$message.success({ showClose: true, message: '删除成功', duration: 2000 })
              this.getListData()
            }
          })
        })
        .catch(() => {})
    },
    //项目列表
    loadProjectOptions() {
      API.queryProjectAll().then(result => {
        if (result && result.code === '000000') {
          this.projectOptions = result.data.list
        }
      })
    },
    //全量更新
    updateCach() {
      API.updateCach().then(result => {
        if (result && result.code === '000000') {
          this.$message.success({ showClose: true, message: '更新成功！', duration: 2000 })
        } else {
          this.$message.success({ showClose: true, message: result.msg, duration: 2000 })
        }
      })
    },
    //显示弹窗数据
    showItems(id) {
      this.configItemVisible = true
      this.configId = id
    }
  }
}
</script>
